<template>
  <p class="auth-prompt-wrapper">
    {{ text }}
    <router-link :to="{name: routeName}" class="link">{{ linkText }}</router-link>
  </p>
</template>

<script>
export default {
  name: 'AuthPrompt',
  props: {
    routeName: {
      type: String,
      required: true,
    },
    linkText: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.auth-prompt-wrapper {
  font-size: rem(16);
  letter-spacing: 0;
  line-height: rem(19);

  .link {
    color: var(--blue_link);
    font-size: rem(16);
    font-weight: 500;
    text-decoration: none;
  }
}

</style>
