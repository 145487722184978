<template>
  <div>
    <!-- Title and subtitle -->
    <h1 class="auth-title forgot-title">{{ $t('FORGOT.TITLE') }}</h1>
    <p class="auth-subtitle">{{ $t('FORGOT.SUBTITLE') }}</p>

    <!-- Form -->
    <FormWrapper :send-form-data="validateData" class="page-form-wrapper">
      <template #form-content>

        <!-- Email -->
        <InputField :field-title="`${$t('EMAIL_ADDRESS')}`"
                    :field-name="'email'"
                    :rules="'required'"
                    :type="'email'"
                    :placeholder="$t('EMAIL_ADDRESS')"
                    :cy-selector="'name-input'"
                    :input-icon="require('../../../../assets/icons/icn_email.svg')"
                    :input-icon-error="require('../../../../assets/icons/icn_error.svg')"
                    :api-error-text="apiErrorText"
                    class="auth-form-element"/>
      </template>
      <template #button-submit>
        <vue-recaptcha :sitekey="recaptchaKey"
                       @verify="onRecaptchaVerified"
        ></vue-recaptcha>
        <button type="submit" class="pink-button auth-button button-send">
          {{ $t('FORGOT.SEND') }}
        </button>
      </template>
    </FormWrapper>

    <!-- Login prompt. -->
    <AuthPrompt :text="$t('RESET.BACK_TO')"
                :route-name="ROUTE_NAMES_AUTH.LOGIN"
                :link-text="$t('RESET.LOGIN')"
                class="auth-prompt"/>
  </div>
</template>

<script>
import FormWrapper from '@/components/yo-form/FormWrapper'
import InputField from '@/components/yo-form/InputField'
import { ROUTE_NAMES_AUTH } from '@/router/modules/auth'
import { useRouter } from 'vue-router'
import AuthPrompt from '@/components/elements/auth/AuthPrompt'
import { useStore } from 'vuex'
import { FORGET_PASSWORD_REQUEST } from '@/store/modules/auth/actions'
import { ref } from '@vue/reactivity'
import { VueRecaptcha } from 'vue-recaptcha';
export default {
  name: 'ForgotPassword',
  components: {
    AuthPrompt,
    FormWrapper,
    InputField,
    VueRecaptcha
  },
  setup() {
    const recaptchaKey = process.env.VUE_APP_RECAPTCHA_KEY
    const router = useRouter()
    const store = useStore()

    const apiErrorText = ref('')
    let recaptchaResponse = ref('')

    function validateData(data) {
      store.dispatch(FORGET_PASSWORD_REQUEST, { email: data.email, recaptchaResponse: recaptchaResponse }).then(response => {
        if (response) {
          router.push({ name: ROUTE_NAMES_AUTH.RESET_LINK_SENT, params: { email: data.email } })
        }
      })
    }

    function onRecaptchaVerified(response){
      recaptchaResponse = response
    }

    return {
      ROUTE_NAMES_AUTH,
      authError: '',
      apiErrorText,

      // Form
      validateData,
      onRecaptchaVerified,
      recaptchaKey
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.forgot-title {
  margin-bottom: rem(14);
}

.button-send {
  min-width: rem(130);
}
</style>
